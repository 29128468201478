.paint-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  gap: 30px;
  margin-top: 30px;
  max-width: 1100px;
}

.paint-item {
  border: none;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s;
  position: relative;
}
.paint-item:hover {
  transform: translateY(-5px);
  border-color: #007bff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.paint-item img {
  background-color: black;
  width: 237px;
  height: 237px;
  transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s;
  position: relative;
  &:hover {
    transform: translateY(-15px);
    border-radius: 18px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}
.content-category {
  display: flex;
  justify-content: start;
  align-items: center;
  /* background-color: white; */
  /* color: black; */
  transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s;
  position: relative;
  border-radius: 8px;
  padding: 5px;
  &:hover {
    /* background-color: #007bff; */
    /* color: white; */
    transform: translateY(-5px);
    border-radius: 18px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.shadow-content-category{
  justify-content: center;
  margin-top: 10px;
}
.content-category-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-category-button {
  padding-inline: 3px;
  margin-left: 10px;
  font-size: 8px;
  /* color: white; */
  border: none;
  background-color: #0056b3;
  border-radius: 8px;
  &:hover {
    color: black;
  }
}
.paint-name {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}
.paint-detail {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  margin-left: 1px;
}

/* .paint-item:hover img {
  transform: scale();
} */
.shade-icon-pdf {
  width: 100px;
  height: 50px;
  color: #f9c818;
  &:hover {
    color: #007bff;
  }
}
.shadow-colour-item {
  padding: 35px 12px;
  margin: 15px 8px;
  border: 1px dotted #f9c818;
}
.category-list-background {
  margin-top: 40px;
  background-image: url("../../../public/category.png"); /* Replace with the actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* color: #ffffff; */
  padding-top: 120px;
}

@media only screen and (max-width: 767px) and (min-width: 350px) {
  .paint-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .product-page-paint-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .paint-item img {
    width: 379px;
    height: 379px;
    /* background-color: black; */
    transition: transform 0.3s ease-in-out;
  }
  .paint-item-product-page img {
    width: 200px;
    height: 200px;
  }
  .paint-name {
    font: 16px "Roboto", sans-serif;
  }
  .paint-detail {
    font-size: 16px;
  }
  .content-category {
    justify-content: center;
  }
  .product-paint-name{
    font-size: 10px;
  }
  .product-paint-detail{
    font-size: 10px;
  }
}
