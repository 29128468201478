.contact-us-container {
  margin: 40px 80px;
}
.main-container-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-head-contact {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 45px;
  margin-bottom: 40px;
  color: black;
}
.map-contactus {
  border: 0;
  width: 570px;
  height: 540px;
}
.user-inputs-message {
  width: 90%;
  height: 103px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
  &:focus {
    outline: none;
    border: 1px dotted #ccc;
  }
}

.user-inputs-contact {
  width: 530px;
  height: 33px;
  padding: 10px;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
    border: 1px dotted #ccc;
  }
}
.contact-section {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  text-align: center;
  padding: 10px;
  width: 100%;
  background-color: #f8c400;
}
.header span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: bold;
}

.contact-info {
  display: flex;
  text-align: center;
}

.info-item {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.info-item span {
  border: none;
  border-bottom: 2px solid #007acc;
  font-size: 20px;
  color: black;
  &:hover {
    color: #f8c400;
    border-bottom: 2px solid black;
  }
}

.submit-button-dealer {
  background-color: #007acc;
  color: white; /* Assuming you want white text on blue background */
  border: none;
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.submit-button-dealer:hover {
  background-color:#ccc;
}


@media only screen and (max-width: 767px) and (min-width: 350px){
    .main-container-contact {
        flex-direction: column;
        justify-content: center;
      }

      .contact-us-container {
        margin: 40px 5px;
        width: 100%;
      }
      .user-inputs-contact {
        width: 100%;
        height: 33px;
        padding: 10px;
        border: 1px solid #ccc;
      }
      .map-contactus {
        border: 0;
        width: 360px;
        height: 250px;
        margin:0 ;
        padding-right: 8px;
      }
      .header {
      width: 80%;
      padding: 0;
      }
      .header span {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 21px;
        font-weight: bold;
      }
      .contact-info {
        display: flex;
        text-align: center;
      }
      .info-item span {
        font-size: 20px;
      }
}
