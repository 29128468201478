.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}

.whatsapp-icon-style {
  width: 50px;
  height: 50px;
}

.hover-message {
  display: none;
  width: 120px;
  position: absolute;
  padding: 2px 0;
  bottom: 10px;
  right: 60px;

  background-color: #25d366; /* WhatsApp green color */
  color: #fff;
  border-radius: 15px;
  opacity: 0;
  animation: slideIn 0.5s ease-in-out;
}

.whatsapp-icon:hover .hover-message {
  display: block;
  opacity: 1;
  animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
