/* .about-us-container {
  margin: 0 auto;
} */

.header-image img {
  width: 100%;
  height: auto;
}

.content-container {
  padding-top: 50px;
  width: 100%;
  background: linear-gradient(to bottom, #0056b3 0%, white 100%);
}

.left-section {
  margin-left: 8%;
}

.let-us-know-text {
  font-family: Helvetica, sans-serif;
  font-size: 48px;
  color: black;
}

.right-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-section p {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 15px 2px;
}

.rounded-border-content {
  width: 1150px;
  border-radius: 130px;
  border: 1px solid black;
  padding: 50px;
  background-color: white;
}

.industries-section {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  padding: 0px 100px 20px 50px;
}

.industries-section h2 {
  width: 100%;
  margin-left: 10%;
  margin-bottom: 20px;
  font-family: Helvetica, sans-serif;
  font-size: 48px;
  color: black;
}

.industries-section ul {
  margin-left: 15%;
}

.industries-section li {
  margin-bottom: 1px;
}
.right-section-industries {
  border: 0.1px solid black;
  border-radius: 60px;
}

.right-section-industries img {
  width: 422px;
  height: 375px;
  border: 2px solid #f9c818;
  border-radius: 60px;
}

.section-strategy {
  display: flex;
  margin: 60px 7%;
}

.left-content img {
  width: 580px;
  height: 320px;
  margin-top: 40px;
  object-fit: cover;
}

.our-strategy-content {
  background-color: white;
  padding: 12px;
  height: 320;
  position: relative;
}

.our-strategy-content h2 {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
}
.our-strategy-content p {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 16px;
  padding: 10px 5px;
  font-size: 16px;
}

.research-section {
  text-align: center;
}

.research-content h2 {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
}

.research-content p {
  align-items: center;
  margin-inline: 8%;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

table {
  width: 80%;
  margin: 40px auto;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

td {
  margin: 10px 10px;
  padding: 20px;
  text-align: justify;
  transition: transform 0.3s, box-shadow 0.5s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon-cell {
  position: relative;
}

.icon-container {
  display: inline-block;
  padding: 5px;
  border: 1px solid #0056b3;
  border-radius: 5px;
  transition: transform 0.3s, opacity 0.3s;
}

.icon-cell:hover .icon-container {
  transform: scale(1.2);
}
.about-us-icon {
  width: 100px;
  height: 100px;
}
@media only screen and (max-width: 767px) and (min-width: 350px) {
  .left-section {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }

  .let-us-know-text {
    font-size: 25px;
  }

  .right-section {
    margin-inline: 20px;
  }
  .right-section p {
    font-size: 13px;
    padding: 0px;
  }

  .rounded-border-content {
    width: 480px;
    height: 600px;
    border-radius: 180px;
    padding: 60px 28px;
  }

  .industries-section {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }

  .industries-section h2 {
    width: 100%;
    margin-left: 0;
    margin: 50px 0 20px 20px;
    font-size: 25px;
  }

  .right-section-industries {
    margin: 20px auto;
    width: 374px;
    height: 375px;
  }

  .right-section-industries img {
    width: 374px;
    height: 375px;
  }
  .section-strategy {
    width: 100%;
    flex-direction: column;
    margin: 60px 7%;
  }

  .left-content img {
    width: 100%;
    height: 242px;
  }

  .our-strategy-content {
    padding: 15px 12px;
    height: 370px;
  }

  .our-strategy-content h2 {
    text-align: center;
    font-size: 25px;
  }
  .our-strategy-content p {
    padding: 10px 0px;
    font-size: 14px;
  }
  .research-content h2 {
    font-size: 25px;
  }

  .research-content p {
    text-align: center;
    margin: 20px 8%;
    font-size: 12px;
  }
  td {
    padding: 10px;
    text-align: justify;
  }
  .icon-container {
    border: nonex ;
  }
  .icon-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-container {
    margin-bottom: 10px;
  }
}
