/* TopBar.css */
.top-bar {
    display: flex;
    justify-content: space-between;
  padding: 8px 10px;
  border-bottom: 0.5px solid #e3e6e8;
}

.left-side {
  font-family: "Roboto", Sans-serif;
  padding: 5px 40px;
}

.right-side {
  padding: 0px 40px;
  margin-left: 30px;
}

.icon {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease;

  &:hover {
    color: #007acc !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 350px) {
  .top-bar {
    justify-content: center;
    background: linear-gradient(45deg, #007acc, #000);
  }

  .right-side {
    padding: 0px;
    margin-left: 0px;
  }
  .icon {
    border-radius: 50%;
    background-color: white;
    padding: 7px;
    color: #007acc !important;
  }
  &:hover {
    color:none;
  }
}
