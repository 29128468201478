.page-head{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    color: black;
}

.page-head h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    color: black;
}
@media only screen and (max-width: 767px) and (min-width: 350px){
    .page-head h1{
        font-size: 25px;
    } 
}