/* Footer.css */

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #007bff;
  color: black;
  margin-top: 100px;
  border-top: 1px solid black;
  box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.2);
  padding: 40px;
  position: relative;
}
.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon img {
  width: 200px;
  height: 225px;
}

.footer-contact {
  flex-direction: column;
  text-align: center;
  height: 220px;
  display: flex;
  padding: 0 80px;
  justify-content: center;
  align-items: center;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer a {
  cursor: pointer;
  text-decoration: none;
  color: black; /* Style your link color */
  margin-right: 10px; /* Adjust as needed */
}
.footer-links a {
  margin-top: 9px;
}
.footer-header {
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.icon-bg {
  display: flex;
  background-color: black;
  border-radius: 50%;
  justify-content: center;
  padding: 4px 6px;
  align-items: center;
  &:hover {
    background-color: #0a3a73;
  }
}

.icon-footer {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.copyright {
  text-align: center;
  padding: 10px;
  width: 85%;
  border-top: 2px solid black;
}
.copyright p {
  color: white;
}
.copyright p a {
  text-decoration: none;
}

@media only screen and (max-width: 767px) and (min-width: 350px) {
  .footer {
    justify-content: center;
    flex-direction: column;
  }
  .footer-contact {
    width: 118%;
    border-right: 2px solid black;
    border-left: 0;
  }
  .social-icons {
    display: flex;
    gap: 10px;
    margin: 20px 0;
  }
  .footer-icon img {
    width: 200px;
    height: 240px;
  }
  .footer-icon {
    width: 118%;
    border-right: 2px solid black;
  }
  .copyright {
    width: 100%;
  }
}
