.heading-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .heading-text {
    padding-bottom: 20px;
    margin-top: 50px;
    border-bottom: 4px solid #007bff;
    font-size: 48px ;
    font-weight: bold;
    color: black;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1s, transform 1s;
  }
  
  .heading-text.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media only screen and (max-width: 767px) and (min-width: 350px) {
    .heading-text{
      margin-top: 50px;
      font-weight: bold !important;
      font: 24px Helvetica sans-serif;
    }
  }
  