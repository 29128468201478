* {
  padding: 0;
  margin: 0;
}
.custom-container {
  display: flex;
  justify-content: flex-start;
  max-width: 100% !important;
  position: relative;
}
.homelogo {
  width: 80px;
  height: 100px;
  margin-left: 25px;
}
.nav-link {
  font-family: "Helvetica", Sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: black !important;
  transition: color 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  color: #0a3a73 !important;
  border-bottom: 3px solid #0a3a73;
  padding-bottom: 8px;
  transition: border 0.1s ease;
}
.custom-link {
  display: flex;
}
.contact-us {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-end; */
  /* margin-right: -200px; */
  /* flex-direction: column; */
  font-weight: bold;
}
.mail {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-inline: 3px;
  padding: 7px 20px;
  background-color: #ed3237;
  border-radius: 23px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #ed3237;
    border:1px solid #ed3237;
  }
}
.number {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-inline: 3px;
  padding: 7px 20px;
  background-color: #0a3a73;
  border-radius: 23px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #0a3a73;
    border:1px solid #0a3a73 ;
  }
}
.whats-app-circle{
  width: 32px;
  height: 32px;
}
.mail-circle{
  width: 32px;
  height: 32px;
}
/* .contact {
  display: flex;
  justify-content: center;
  background-color: black;
  margin-top: 10px;
  color: white;
  padding: 12px 40px;
  border-radius: 23px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #f9c818;
    transition: color 0.3s ease;
  }
} */
.mobile-menu-btn {
  display: none;
}
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

@media only screen and (max-width: 767px) and (min-width: 350px) {
  .navbar-nav {
    display: none;
  }
  /* .navbar-nav {
    display: block;
    max-height: 0; 
    overflow: hidden;
    transition: max-height 0.5s ease;
  } */
  .nav-link {
    padding-left: 12%;
    color: #494c4f !important;
  }

  .mobile-menu-btn {
    display: block;
    border: none;
    color: #f9c818;
    background-color: white;
  }
  /* Add this CSS to your stylesheet */

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mobile-menu-open {
    animation: fadeInDown 0.5s ease; /* Opening animation */
  }

  .mobile-menu-open {
    width: 100%;
    display: flex;
    max-height: 500px;
    transition: max-height 2s ease;
    justify-content: center;
    align-items: flex-start !important;
    position: absolute;
    top: 80%;
    left: 0;
    background-color: white;
    opacity: 1;
    z-index: 1000;
  }
  .nav-link:hover,
  .nav-link.active {
    width: 100%;
    /* color: #494c4f !important;
    background-color: #f9c818; */
    transition: background-color 0.3s ease;
    transition: border 0.1s ease;
  }
  .navbar-toggle-icon {
    font-size: 24px; /* Adjust the font size as needed */
    background-color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: bold;

    &:hover {
      background-color: #0a3a73;
      color: white;
      font-weight: bold;
    }
  }
  .cross-icon {
    font-size: 24px; /* Adjust the font size as needed */
    color: black;
    background-color: #0a3a73;
    padding: 4px 8px;
    font-weight: bold;
    border-radius: 4px;
  }
}
